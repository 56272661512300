import { FeatureCallout, FeatureCallouts } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {
  featureCallouts as featureCalloutsClass,
  marginContainer,
  wrap
} from './FeatureCalloutsModule.module.css';

export default function FeatureCalloutsModule({
  blobs,
  className,
  data
}) {
  const {
    featureCallouts,
    hasMarginBottom,
    reverseFeatureCallouts,
    title
  } = data;
  const featureCalloutClasses = classNames(className, featureCalloutsClass);
  const classes = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={classes}>
      {blobs}
      <FeatureCallouts
        className={featureCalloutClasses}
        reversedChildren={reverseFeatureCallouts}
        title={title}
      >
        {featureCallouts.map((item) => (
          <FeatureCallout
            key={item.title}
            copy={item.copy.copy}
            ctaCopy={item.ctaCopy}
            ctaTrackingClass={item.ctaTrackingClass}
            ctaUrl={item.ctaUrl}
            imageAlt={item.imageAlt}
            imageSrcFluid={item.image && item.image.fluid}
            mobileCtaCopy={item.mobileCtaCopy}
            mobileCtaTrackingClass={item.mobileCtaTrackingClass}
            mobileCtaUrl={item.mobileCtaUrl}
            title={item.title}
            videoMp4={item.videoUrl}
            videoPoster={item.videoPoster && item.videoPoster.fluid.srcWebp}
          />
        ))}
      </FeatureCallouts>
    </div>
  );
}

export const FeatureCalloutsDataPropsObject = {
  featureCallouts : PropTypes.arrayOf(PropTypes.shape({
    copy : PropTypes.shape({
      copy : PropTypes.string
    }),
    ctaCopy          : PropTypes.string,
    ctaTrackingClass : PropTypes.string,
    ctaUrl           : PropTypes.string,
    image            : PropTypes.shape({
      fluid : PropTypes.shape({
        aspectRatio : PropTypes.number,
        sizes       : PropTypes.string,
        src         : PropTypes.string,
        srcSet      : PropTypes.string,
        srcSetWebp  : PropTypes.string,
        srcWebp     : PropTypes.string
      })
    }),
    imageAlt               : PropTypes.string,
    mobileCtaCopy          : PropTypes.string,
    mobileCtaTrackingClass : PropTypes.string,
    mobileCtaUrl           : PropTypes.string,
    title                  : PropTypes.string,
    videoPoster            : PropTypes.shape({
      fluid : PropTypes.shape({
        srcWebp : PropTypes.string
      })
    }),
    videoUrl : PropTypes.string
  })),
  hasMarginBottom        : PropTypes.boolean,
  reverseFeatureCallouts : PropTypes.bool,
  title                  : PropTypes.string
};

FeatureCalloutsModule.propTypes = {
  blobs     : PropTypes.node,
  className : PropTypes.string,
  data      : PropTypes.shape(FeatureCalloutsDataPropsObject).isRequired
};

FeatureCalloutsModule.defaultProps = {
  blobs     : null,
  className : null
};

export const FeatureCalloutsModuleData = graphql`
  fragment FeatureCalloutsModuleData on ContentfulComponentFeatureCallouts {
    featureCallouts {
      copy {
        copy
      }
      ctaCopy
      ctaTrackingClass
      ctaUrl
      imageAlt
      image {
        fluid(maxWidth: 720) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileCtaCopy
      mobileCtaUrl
      mobileCtaTrackingClass
      title
      videoPoster {
        fluid(maxWidth: 720) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      videoUrl
    }
    hasMarginBottom
    reverseFeatureCallouts
    title
  }
`;
