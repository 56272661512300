// extracted by mini-css-extract-plugin
export var base = "FeatureCalloutsModule-module--base--3EtEM";
export var rightAlignedContainer = "FeatureCalloutsModule-module--rightAlignedContainer--1bLqm";
export var rightAlignedContainerSmall = "FeatureCalloutsModule-module--rightAlignedContainerSmall--rnjkA";
export var leftAlignedContainer = "FeatureCalloutsModule-module--leftAlignedContainer--1n95f";
export var blogContainer = "FeatureCalloutsModule-module--blogContainer--2VRs8 FeatureCalloutsModule-module--base--3EtEM";
export var container = "FeatureCalloutsModule-module--container--JKOO1 FeatureCalloutsModule-module--base--3EtEM";
export var largeContainer = "FeatureCalloutsModule-module--largeContainer--1J_oi FeatureCalloutsModule-module--base--3EtEM";
export var mobileSmallPadding = "FeatureCalloutsModule-module--mobileSmallPadding--340sY";
export var fullWidthMobile = "FeatureCalloutsModule-module--fullWidthMobile--3ItvY";
export var gridWithSidebar = "FeatureCalloutsModule-module--gridWithSidebar--1xU3e";
export var noMaxWidth = "FeatureCalloutsModule-module--noMaxWidth--35GDn";
export var wrap = "FeatureCalloutsModule-module--wrap--3ob4r";
export var featureCallouts = "FeatureCalloutsModule-module--featureCallouts--1QbeP FeatureCalloutsModule-module--container--JKOO1 FeatureCalloutsModule-module--base--3EtEM FeatureCalloutsModule-module--mobileSmallPadding--340sY";
export var marginContainer = "FeatureCalloutsModule-module--marginContainer--mAHbN";